import React from "react";
import {Col, Row} from "antd";
import ItemFirst from "./ItemFirst";
import ItemSecond from "./ItemSecond";
import ItemThird from "./ItemThird";
import { Modal } from 'antd';
import SetPayment from './set_payment'
import Api from '../../Api'
import { Result, Button } from 'antd';

export default class App extends React.Component {
 
 state = { visible: false,price:0,type:'',end:false };

  showModal = () => {
    this.setState({
      end:false,
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      end:false,
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      end:false,
      visible: false,
    });
  };


 render() {
    return (
      <div className="gx-price-tables gx-pt-default">
        <Row>
          <Col xl={8} lg={24} md={8} xs={24}>
            <ItemFirst
              styleName="gx-package"
              headerStyle="gx-package-header gx-bg-primary gx-text-white"
              itemStyle="gx-package-body"
              footerStyle=""
            />
          </Col>

          <Col xl={8} lg={24} md={8} xs={24}>
            <ItemSecond
              styleName="gx-package gx-bg-primary-light gx-highlight gx-border-0"
              headerStyle="gx-package-header gx-bg-primary gx-text-white"
              itemStyle="gx-package-body gx-text-white"
              footerStyle="gx-btn-primary"
              payment={()=>{ this.setState({price:29,visible:true,type:'SINGLE'})  }}
            />
          </Col>

          <Col xl={8} lg={24} md={8} xs={24}>
            <ItemThird
              styleName="gx-package"
              headerStyle="gx-package-header gx-bg-primary gx-text-white"
              itemStyle="gx-package-body"
              footerStyle=""
              payment={()=>{ this.setState({price:49,visible:true,type:'ENTERPRISE'})  }}
            />
          </Col>
        </Row>

         <Modal
          title="Make a purchase"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
        { this.state.end &&
            <Result
            status="success"
            title="Payment completed"
            subTitle="The payment was made successfully"
          
          />
        }

        { this.state.end==false &&
          <SetPayment price={this.state.price} payment={(card)=>{ Api.Payment(card,this.state.price,this.state.type).then((data)=>{  if(data.error!=undefined){ alert(data.error) }else{ this.setState({end:true}) } }) }}/>
        
        }
        </Modal>


      </div>
    )
  }
}


