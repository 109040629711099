import React, {Component} from "react";
import {Button, Card, message, Modal,Steps,Input,Tag,Tooltip, Icon,Result,Progress,Tabs,Row,Col } from "antd";
import Api from '../../Api'
import AmCharts from "@amcharts/amcharts3-react";
import Map from './Map'
import FullTableResults from './FullTableResults'
import EditedTableResults from './charts/EditedTableResults'
import CategoriesByCount from './charts/categories_by_count'
import CountriesByProportion from './charts/countries_by_proportion'
import BusinessStartDatesByCount from './charts/business_start_dates_by_count'
import PatentInformation from './charts/patent_information'
import ResearcherInformation from './charts/researcher_information'
import ResultsBySource from './charts/results_by_source'
import ResultsBySourceLine from './charts/results_by_source_line'
import PatentDocumentsResults from './charts/patent_documents_results'
import PotentialLicensees from './charts/potential_licensees'
import ClinicalMap from './charts/clinical_map'
import ScoredMatches from './charts/scored_matches'
import CountByYear from './charts/count_by_year'
import Categories from './charts/categories'


const { TabPane } = Tabs;
const Step = Steps.Step;
const {TextArea} = Input;

class SwitchStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basic:true,
      data:this.props.data,
      modal_visible: false,
      modal_title:'',
      modal_body:null,
      progress:0,
      title:'',
      abstract:'',
      current: 0,
      tags: [],
      emails:[],
      inputVisible: false,
      emailInputVisible: false,
      inputValue: '',
      emailInputValue: '',
    };
  }

  componentDidMount() {

    Api.Account().then((acc)=>{

      var basic = true

      if(acc.data.account_type=='SINGLE'){
        basic = false
      }
      if(acc.data.account_type=='ENTERPRISE'){
        basic = false
      }
      this.setState({basic:basic})
    })
  }

  showModal(title,body){
    this.setState({modal_title:title,modal_visible:true,modal_body:body})
  }
  handleOk = e => {
   
    this.setState({
      modal_visible: false,
    });
  };

  handleCancel = e => {
    
    this.setState({
      modal_visible: false,
    });
  };


  

  prev() {
    const current = this.state.current - 1;
    this.setState({current});
  }

  render() {
    


    return (
      <div>
     

      
        <Card className="gx-card" title="Results">
          <h1>{this.props.title}</h1>
          <h4>{this.props.keywords}</h4>
          <Tabs defaultActiveKey="1" onChange={()=>{  }}>
            <TabPane tab="Researchers" key="1">
              <h2 style={{textAlign:'center'}}>Researcher Information</h2>
              <p style={{textAlign:'center'}}>These visualizations are from the results of our AI, "searching and matching", the powerful union of over 3 million scholarly articles, conference papers, images, journal articles, photos, meeting papers, movies, patents, research reports, and technical videos.</p>
              <ResearcherInformation showModal={this.showModal.bind(this)} data={this.state.data} />
              <h2 style={{textAlign:'center',marginTop:20}}>Results by Source</h2>


              <Row>
                <Col span={16} push={8}>
                  <ResultsBySourceLine data={this.state.data} />
                </Col>
                <Col span={8} pull={16}>
                  <ResultsBySource data={this.state.data} />
                </Col>
              </Row>

              
              


            </TabPane>
            <TabPane tab="Patent" key="2">
              
              <h2 style={{textAlign:'center',marginTop:20}}>Patent Information</h2>
              <p>These visualizations are from the results of our AI, "searching and matching" over 11 million intellectual property protection applications.</p>
              <PatentInformation data={this.state.data} />
              <h2 style={{textAlign:'center',marginTop:20}}>Patent Documents Results</h2>

              <PatentDocumentsResults showModal={this.showModal.bind(this)} data={this.state.data} />

            </TabPane>

            {this.state.basic==false&&
            <TabPane tab="Enterprise" key="3">
              <h3 style={{textAlign:'center'}}>Potential Licensees</h3>
              <p style={{textAlign:'center'}}>Global search results from over 9 million businesses.</p>
              <PotentialLicensees showModal={this.showModal.bind(this)} data={this.state.data} />
              
              <h3 style={{textAlign:'center'}}>Global Reach: Mapping Countries by Count</h3>
              <p style={{textAlign:'center'}}>
               If load time is slow, try resizing the browser widow. Please be patient with load times because they are staged to meet requirements for a variety of browsers.
              </p>
              <Map data={this.state.data}  />
              

              <Row>
                <Col span={12} push={12}>
                  <h2 style={{textAlign:'center',marginTop:20}}>Categories by Count</h2>
                  <CategoriesByCount data={this.state.data}/>

                </Col>
                <Col span={12} pull={12}>
                  <h2 style={{textAlign:'center',marginTop:20}}>Countries by Proportion</h2>
                  <CountriesByProportion data={this.state.data} />
                </Col>
              </Row>

              <h2 style={{textAlign:'center',marginTop:20}}>Business Start Dates by Count</h2>
                <BusinessStartDatesByCount data={this.state.data} />


            </TabPane>
            }
            {this.state.basic==false&&
            <TabPane tab="Clinical Trials" key="4">
              
              <h3 style={{textAlign:'center'}}>Clinical Trials</h3>
              <ClinicalMap data={this.state.data}  />
              
              <h3 style={{textAlign:'center'}}>Scored Matches</h3>
              <ScoredMatches showModal={this.showModal.bind(this)}  data={this.state.data}  />

              <h3 style={{textAlign:'center'}}>CountB by Year</h3>
              <CountByYear data={this.state.data}  />

              <h3 style={{textAlign:'center'}}>Categories</h3>
              <Categories data={this.state.data} />


            </TabPane>
            }
            {this.state.basic==false&&
            <TabPane tab="Full Table Results" key="5">
              <h3 style={{textAlign:'center'}}>Full Table Results</h3>
              <p style={{textAlign:'center'}}>This table represents the combined results of the best matches accross multiple datasources. Note: Column named "Organizations" reflects potential licensee enterprises where appropriate.</p>

              <p style={{textAlign:'center'}}>Type something in the input field to search the table for matching content:</p>
              <FullTableResults data={this.state.data}  />
            </TabPane>
            }
            {this.state.basic==false&&
            <TabPane tab="Edited Table Results" key="6">
              <h3 style={{textAlign:'center'}}>Edited Table Results</h3>
              <EditedTableResults data={this.state.data} />
            </TabPane>
            }
          </Tabs>
        </Card>
      


      <Modal
          title={this.state.modal_title}
          visible={this.state.modal_visible}
          footer={[<Button onClick={this.handleOk}>to close</Button>]}
        >
        {this.state.modal_body}
        </Modal>

      </div>
    );
  }
}


export default SwitchStep;
