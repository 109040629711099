import React, {Component} from "react";
import {Button, Card, message, Steps,Input,Tag,Tooltip, Icon,Result,Progress,Tabs,Row,Col } from "antd";
import AmCharts from "@amcharts/amcharts3-react";
import "./index.css";

class SwitchStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }





  data_results_source(){

    const countOccurrences = years => years.reduce((prev, curr) => (prev[curr] = ++prev[curr] || 1, prev), {});
    
    let categories = []
    
    for(var  i in this.props.data){
      var row = this.props.data[i]
      

      if(row['Datasource']==="ClinicalTrials.gov"){
            let category = row['Categories'];
            categories.push(category);
        }


    }

  let categoryDict = countOccurrences(categories)
  var chartData = []
    for (const [key, value] of Object.entries(categoryDict)) {

      if(key != 'Unknown'){
        chartData.push({year:key,value:value})
      }
    }

  return {
    "type": "pie",
    "hideCredits":true,
    "theme": "light",
    "dataProvider": chartData,
    "valueField": "value",
    "titleField": "year",
    "balloon": {
      "fixedPosition": true
    },
    "export": {
      "enabled": true
    }
  };

  }


  render() {
    return (<AmCharts.React style={{width: "100%", height: "500px"}} options={this.data_results_source()}/>);
  }
}


export default SwitchStep;
