import React, {Component} from "react";
import {Spin,Button, Card, message, Modal,Steps,Input,Tag,Tooltip, Icon,Result,Progress,Tabs,Row,Col } from "antd";
import Api from '../../Api'
import AmCharts from "@amcharts/amcharts3-react";
import Map from '../submission/Map'
import Graficas from '../submission/Graficas'


const { TabPane } = Tabs;
const Step = Steps.Step;
const {TextArea} = Input;

class SwitchStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      data:[],
      progress:0,
      title:'',
      abstract:'',
      current: 4,
      load:true
    };
  }


  componentWillUnmount() {
    Api.RemoveSoket('inciteai_submission/'+this.state.id)
  }
  componentDidMount() {
    
    Api.public_submission_get(this.props.match.params.id).then((data)=>{

        
        if(data.data.progress!=100){
          this.setState({progress:data.data.progress,current:4,id:data.data.id,load:false})
          Api.Soket('inciteai_submission/'+data.data.id,(sd)=>{

              if(sd.progress==100){
                this.setState({progress:sd.progress,current:4,data:JSON.parse(sd.json)})
              }else{
                this.setState({progress:sd.progress,current:4})
              }
          })
        }else{
          var json = JSON.parse(data.data.json)
          this.setState({progress:data.data.progress,current:4,data:json,load:false })
        }
    })
    
  }

  render() {


    if(this.state.load){
      return (<div style={{textAlign:'center'}}><Spin  style={{marginTop:150}} size="large" /></div>)
    }


    const {emails,tags, inputVisible, inputValue ,current,emailInputValue,emailInputVisible} = this.state;


    const steps = [{
      title: 'Project Title',
      content: (<div style={{margin:50}} ></div>),
    }, {
      title: 'Input Search Keywords',
      content: (<div style={{paddingTop:100}}></div>),
          }, {
            title: 'Project Description',
            content: (<div style={{margin:30}}></div>),
          },{
            title: 'Send',
            content: (<div style={{paddingTop:100}}></div>),
          },{
            title: 'Progress',
            content: (<div style={{margin:30}}>
              <p>Your Results are in Process!
                Please note that most results are completed within 5 minutes. However, depending on the complexity of the request and speed of off site servers and API throttling, the process can take up to 15 minutes.</p>
              <p>Processing time is reflected in progress bar below.
                Feel free to wait and/or request an email to be send with a link to results by using the form below.</p>
              <Progress type="circle" percent={this.state.progress} /></div>),
          }];




    return (
      <div style={{margin: 'auto',width:'80%'}}>

      <div style={{marginTop:50}}>
      {  this.state.progress != 100 &&
      <Card  className="gx-card" title="Switch Step">
        <Steps current={current}>
          {steps.map(item => <Step key={item.title} title={item.title}/>)}
        </Steps>
        <div className="steps-content">{steps[this.state.current].content}</div>
        <div className="steps-action">
        
        </div>
      </Card>
      }


      {  this.state.progress == 100 &&

        <Graficas data={this.state.data} />
      }

      </div>

      </div>
    );
  }
}


export default SwitchStep;
