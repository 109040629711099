import React from "react";
import {Button} from "antd";
import IntlMessages from "util/IntlMessages";
import PaymentCard from 'react-payment-card-component'

const
  PriceItem = ({styleName, headerStyle, itemStyle, footerStyle,onSelect}) => {
    return (
      <div className={`${styleName}`}>
        <div className={`${headerStyle}`}>
          <h2 className="gx-price"><i className="icon icon-halfstar"/>$0.00</h2>
          <p className="gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0">BASIC ACCOUNT</p>
          
        </div>

        <div className={`${itemStyle}`}>
          <ul className="gx-package-items">
            <li>
            <i className="icon icon-check"/>

              <span>3 searches per month</span>
            </li>
            <li>
              <i className="icon icon-check"/>
              <span>Access to only free graphs</span>
            </li>
          </ul>
          <div className="gx-package-footer">

          </div>
        </div>

      </div>
    )
  };

export default PriceItem;

