import React from "react";
import socketIOClient from "socket.io-client";


export default class Api  {
  
  static URL='https://api.inciteai.us'
  //static URL='http://localhost:8080'
  
  static GetApiUrl(){


    
    if(process.env.API_URL==undefined){
      return Api.URL
    }else{
      return process.env.API_URL
    }
  }
  static socket = socketIOClient(Api.GetApiUrl());
  static Play(m,text){
    var audio = new Audio(Api.GetApiUrl()+'/ispeech/'+m+'/'+text)
    audio.play()
  }
  static Accion(model,accion,data){
      
      return fetch(Api.GetApiUrl()+"/accion/"+model+'/'+accion,{
        method: 'POST',
        
        body:JSON.stringify({data:data,user:"1"})
      })
      .then((response) => response.json())
                
  }
  static getImg(model,field,id){
    return Api.GetApiUrl()+'/images/'+model+'/'+field+'/'+id
  }

  static SingUp(user,name,pass,organization){
     return fetch(Api.GetApiUrl()+"/user/singup",{
        method: 'POST',
        
        body:JSON.stringify({user:user,name:name,password:pass,organization:organization})
      })
      .then((response) => response.json())
  }
  static Login(user,pass){
     return fetch(Api.GetApiUrl()+"/user/login",{
        method: 'POST',
        
        body:JSON.stringify({user:user,password:pass})
      })
      .then((response) => response.json())
  }


  static Payment(card,price,type){
     return fetch(Api.GetApiUrl()+"/user/payment",{
        method: 'POST',
        
        body:JSON.stringify({type:type,card:card,price:price,token: localStorage.getItem('user_id')})
      })
      .then((response) => response.json())
  }


  static SaveUserGeo(){
      
   return fetch('https://geolocation-db.com/json/').then((response) => response.json()).then((res)=>{
      
      var userLang = navigator.language || navigator.userLanguage; 

      return fetch(Api.GetApiUrl()+"/inciteai/account/geo",{
        method: 'POST',
        body:JSON.stringify({data:{...res,lang:userLang},token: localStorage.getItem('user_id') })
      })
      .then((response) => response.json()) 
    })
    

                
  }


  static share_add(email,project){
      
   return fetch(Api.GetApiUrl()+"/inciteai/share/add",{
      method: 'POST',
      
      body:JSON.stringify({data:{email:email,project:project},token: localStorage.getItem('user_id') })
    })
    .then((response) => response.json()) 
    

                
  }

  static recover_password(email){
      
   return fetch(Api.GetApiUrl()+"/inciteai/recover/password",{
      method: 'POST',
      
      body:JSON.stringify({email:email})
    })
    .then((response) => response.json()) 
    

                
  }

    static recover_password_get(token){
      
   return fetch(Api.GetApiUrl()+"/inciteai/recover/password/get",{
      method: 'POST',
      
      body:JSON.stringify({token:token})
    })
    .then((response) => response.json()) 
    

                
  }

    static recover_password_update(password,token){
      
   return fetch(Api.GetApiUrl()+"/inciteai/recover/password/update",{
      method: 'POST',
      
      body:JSON.stringify({password:password,token:token})
    })
    .then((response) => response.json()) 
    

                
  }
  static share_remove(id){
      
   return fetch(Api.GetApiUrl()+"/inciteai/share/remove",{
      method: 'POST',
      
      body:JSON.stringify({data:{id:id},token: localStorage.getItem('user_id') })
    })
    .then((response) => response.json()) 
    

                
  }


  static share_list(project){
      
   return fetch(Api.GetApiUrl()+"/inciteai/share/list",{
      method: 'POST',
      
      body:JSON.stringify({data:{project:project},token: localStorage.getItem('user_id') })
    })
    .then((response) => response.json()) 
    

                
  }



    static Account(){
      
    return fetch(Api.GetApiUrl()+"/inciteai/account",{
      method: 'POST',
      body:JSON.stringify({token: localStorage.getItem('user_id') })
    })
    .then((response) => response.json()) 

                
  }

    static AccountSave(name,organization){
      
    return fetch(Api.GetApiUrl()+"/inciteai/account/save",{
      method: 'POST',
      
      body:JSON.stringify({data:{name:name,organization:organization},token: localStorage.getItem('user_id') })
    })
    .then((response) => response.json()) 

                
  }

  static AccountPassword(password,n_password){
      
    return fetch(Api.GetApiUrl()+"/inciteai/account/password",{
      method: 'POST',
      
      body:JSON.stringify({password:password,n_password:n_password,token: localStorage.getItem('user_id') })
    })
    .then((response) => response.json()) 

                
  }
  static Get(model,id){
      
      return fetch(Api.GetApiUrl()+"/get/"+model+'/'+id,{
        method: 'POST',
        
      })
      .then((response) => response.json())
                
  }

  static Save(model,data){
      
    return fetch(Api.GetApiUrl()+"/inciteai/submission",{
      method: 'POST',
      
      body:JSON.stringify({data:data,token: localStorage.getItem('user_id') })
    })
    .then((response) => response.json()) 

                
  }


  static Change(model,fun,data){
      
    return fetch(Api.GetApiUrl()+"/change/"+model+"/"+fun,{
          method: 'POST',
          
          body:JSON.stringify({data:data,uid:"1"})
        })
    .then((response) => response.json()) 

                
  }


  static SubmissionList(){
      
      return fetch(Api.GetApiUrl()+"/inciteai/submission/list",{
                  method: 'POST',
            
                  body:JSON.stringify({token: localStorage.getItem('user_id')})
                })
                .then((response) => response.json())
                .then((data) => {

                  return data                  
                }) 
                
  }

  static SubmissionGet(id){
      
      return fetch(Api.GetApiUrl()+"/inciteai/submission/get",{
                  method: 'POST',
            
                  body:JSON.stringify({id:id,token: localStorage.getItem('user_id')})
                })
                .then((response) => response.json())
                .then((data) => {

                  return data                  
                }) 
                
  }

  static public_submission_get(id){
      
      return fetch(Api.GetApiUrl()+"/inciteai/submission/public/get",{
                  method: 'POST',
            
                  body:JSON.stringify({token:id})
                })
                .then((response) => response.json())
                .then((data) => {

                  return data                  
                }) 
                
  }




  static Soket(model,event){
    
    Api.socket.on(model, data => {
        console.log(data)
        event(data.data)
    });
  }

  static RemoveSoket(model){
    
    Api.socket.off(model);
  }
  
}
