import React, {Component} from "react";
import {Button, Card, message, Steps,Input,Tag,Tooltip, Icon,Result,Progress,Tabs,Row,Col } from "antd";
import AmCharts from "@amcharts/amcharts3-react";
import "./index.css";
class SwitchStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  data_patent_information(){

    var res = []
    var years  = []

    for(var  i in this.props.data){
      var dataRow= this.props.data[i]
      if(dataRow['Datasource']==='USPTO'){

        let year = dataRow['Date'].substr(0,4);
            
            years.push(year);
      }
      
    }

    const countOccurrences = years => years.reduce((prev, curr) => (prev[curr] = ++prev[curr] || 1, prev), {});

    var usptoYearCount = countOccurrences(years)

    let labels = []
    let values = []
    for (const [key, value] of Object.entries(usptoYearCount)) {
        labels.push(key);
        values.push(value);

        res.push({
          "year": key,
          "count": value
        })

      }



    


  return {
    "type": "serial",
    "theme": "light",
    "hideCredits":true,
    "dataProvider": res,
    "valueAxes": [{
      "gridColor": "#FFFFFF",
      "gridAlpha": 0.2,
      "dashLength": 0
    }],
    "gridAboveGraphs": true,
    "startDuration": 1,
    "chartScrollbar": {
      "graph": "g1",
      "scrollbarHeight": 40,
      "backgroundAlpha": 0,
      "selectedBackgroundAlpha": 0.1,
      "selectedBackgroundColor": "#888888",
      "graphFillAlpha": 0,
      "graphLineAlpha": 0.5,
      "selectedGraphFillAlpha": 0,
      "selectedGraphLineAlpha": 1,
      "autoGridCount": true,
      "color": "#AAAAAA"
    },
    "graphs": [{
      "balloonText": "[[category]]: <b>[[value]]</b>",
      "fillAlphas": 0.8,
      "lineAlpha": 0.2,
      "type": "column",
      "valueField": "count"
    }],
    "chartCursor": {
      "categoryBalloonEnabled": false,
      "cursorAlpha": 0,
      "zoomable": false
    },
    "categoryField": "year",
    "categoryAxis": {
      "gridPosition": "start",
      "gridAlpha": 0,
      "tickPosition": "start",
      "tickLength": 20
    },
    "export": {
      "enabled": true
    }

  };

  }



  

  render() {
    return (<AmCharts.React style={{width: "100%", height: "500px"}} options={this.data_patent_information()}/>);
  }
}


export default SwitchStep;
