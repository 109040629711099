import React from 'react';
import { render } from 'react-dom';
import Card from 'react-credit-cards';
import {Col, Row,Input,Button} from "antd";
import SupportedCards from './Cards';

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
} from './utils';
import styles from './styles.css';

import 'react-credit-cards/es/styles-compiled.css';

export default class App extends React.Component {
  state = {
    number: '',
    name: '',
    expiry: '',
    cvc: '',
    issuer: '',
    focused: '',
    formData: null,
  };

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { issuer } = this.state;
    const formData = [...e.target.elements]
      .filter(d => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});
    
    this.setState({ formData });
    this.form.reset();
  };

  render() {
    const { name, number, expiry, cvc, focused, issuer, formData } = this.state;

    return (
      <div key="Payment">
        <div className="App-payment">
          <h1>Amount ${this.props.price}.00</h1>
          <Card
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={this.handleCallback}
          />
          <form ref={c => (this.form = c)} onSubmit={this.handleSubmit}>


              <Row>

                <Col xl={24}>

                    <input
                    type="tel"
                    name="number"
                    className="form-control"
                    placeholder="Card Number"
                    pattern="[\d| ]{16,22}"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    />
                </Col>
              </Row>
              <br/>
             <Row>

              <Col xl={24}>
              <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </Col>
            </Row>


            <br/>
             <Row>

              <Col xl={12} >
                <input
                  type="tel"
                  name="expiry"
                  styles={{marginRight:5}}
                  className="form-control"
                  placeholder="Valid Thru"
                  pattern="\d\d/\d\d"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </Col>
              <Col xl={12}  >
                 <input
                  type="tel"
                  name="cvc"
                  className="form-control"
                  placeholder="CVC"
                  pattern="\d{3,4}"
                  required
                  styles={{marginLeft:5}}
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </Col>

            </Row>

             <br/>
            <input type="hidden" name="issuer" value={issuer} />
            <Row>

              <Col xl={24}>
                <Button onClick={()=>{ this.props.payment({name:name, number:number, expiry:expiry, cvc:cvc}); /*this.setState({name:'', number:'', expiry:'', cvc:''})*/  }} type="primary">PAY</Button>
              </Col>
            </Row>
          </form>
        
        </div>
      </div>
    );
  }
}

