import React, {Component} from "react";
import {Button, Card, message, Steps,Input,Tag,Tooltip, Icon,Result,Progress,Tabs,Row,Col } from "antd";
import AmCharts from "@amcharts/amcharts3-react";
import "./index.css";

class SwitchStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }



  data_results_source(){

    const countOccurrences = years => years.reduce((prev, curr) => (prev[curr] = ++prev[curr] || 1, prev), {});
    
    let years = []
    let categoryList=[];
    let scoresList = [];
    let randomColors=[];
    let titleList = [];
    let pdlList = [];
    let countries = [];
    
    for(var  i in this.props.data){
      var dataRow = this.props.data[i]
      

        if(dataRow['Datasource']==='PDL'){
            let pdlRow = dataRow['Datasource']
            let country = dataRow['Country']
            let year = dataRow['Date'].substr(0,4);

            let category = dataRow['Categories'];
            let scores = dataRow['Score']
            let title = dataRow['Organization'].replace(/(\S+\s*){1,11}/g, "$&\n")
            let link = dataRow['Url'];
            let abstract = dataRow['Text'].replace(/(\S+\s*){1,11}/g, "$&\n")
            titleList.push('Title: ' + title + '  ----  '+'Description: ' + abstract + '  ----  '+ 'Link: ' + link);
            pdlList.push(pdlRow)
            scoresList.push(scores)
            if(year != ''){
                years.push(year);
            }

            categoryList.push(category)
            if(country !=''){

                countries.push(country);
            }


        }


    }
    
    let categoryDict = countOccurrences(countries)
    var res = []
    for (const [key, value] of Object.entries(categoryDict)) {
        
        res.push({
        "countrie": key,
        "count": value
      })

    }

  return {
    "type": "pie",
    "hideCredits":true,
    "theme": "light",
    "dataProvider": res,
    "valueField": "count",
    "titleField": "countrie",
    "balloon": {
      "fixedPosition": true
    },
    "export": {
      "enabled": true
    }
  };

  }


  render() {
    
    return (<AmCharts.React style={{width: "100%", height: "500px"}} options={this.data_results_source()}/>);
  }
}


export default SwitchStep;
