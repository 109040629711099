import React, {Component} from "react";
import {Button, Card, message, Steps,Input,Tag,Tooltip, Icon,Result,Progress,Tabs,Row,Col } from "antd";
import AmCharts from "@amcharts/amcharts3-react";
import "./index.css";

class SwitchStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  data_results_source_line(){

    function range(start, stop, step) {
        var a = [start], b = start;
        while (b < stop) {
            a.push(b += step || 1);
        }
        return a;
    }
    
    let ntrsYears = []
    let arxivYears = []
    let minYear = 42000;
    let maxYear = 0;
    
    for(var  i in this.props.data){
      var element = this.props.data[i]
      // console.log('line.js' + element)

        let year = element['Date'].substr(0,4);
        // console.log(year )
        let source = element['Datasource']

        // console.log(year, source)
        year=parseInt(year);
        if(source === 'NTRS' || source === 'ARXIV'){
            if(year < minYear){
                minYear = year;
            }
            
            if(year > maxYear){
                maxYear = year;
            }

        }


        if(source==='NTRS'){
            ntrsYears.push(year)

        }

        if(source==='ARXIV'){
            arxivYears.push(year)
        }
        


    }



    let labels = range(minYear, maxYear);    
   
   
    let ntrsLabels = []
    let arxivLabels = []
    let ntrsValues = []
    let arxivValues = []
    var chartData = []
    const countOccurrences = arr => arr.reduce((prev, curr) => (prev[curr] = ++prev[curr] || 1, prev), {});

    let ntrsYearCount = countOccurrences(ntrsYears)    
    let arxivYearCount = countOccurrences(arxivYears) 

    for(var year of labels){
        if(ntrsYearCount[year]){
            ntrsValues.push(ntrsYearCount[year]);
        } else{
            ntrsValues.push(0);
        }
        if(arxivYearCount[year]){
            arxivValues.push(arxivYearCount[year]);
        } else{
            arxivValues.push(0);
        }


        chartData.push({year:year,arxiv:arxivYearCount[year],ntrs:ntrsYearCount[year]})
    }


  return {
    "type": "serial",
    "hideCredits":true,
    "theme": "light",
    "legend": {
      "useGraphSettings": true
    },
    "dataProvider": chartData,
    "synchronizeGrid": true,
    "valueAxes": [{
      "id": "v1",
      "axisColor": "#FF6600",
      "axisThickness": 2,
      "axisAlpha": 1,
      "position": "left"
    }],
    "graphs": [{
      "valueAxis": "v1",
      "lineColor": "#FF6600",
      "bullet": "round",
      "bulletBorderThickness": 1,
      "hideBulletsCount": 30,
      "title": "#ARXIV by Year",
      "valueField": "arxiv",
      "fillAlphas": 0
    }, {
      "valueAxis": "v2",
      "lineColor": "#FCD202",
      "bullet": "square",
      "bulletBorderThickness": 1,
      "hideBulletsCount": 30,
      "title": "#NTRS by Year",
      "valueField": "ntrs",
      "fillAlphas": 0
    }],
    "chartScrollbar": {},
    "chartCursor": {
      "cursorPosition": "mouse"
    },
    "categoryField": "year",
    "categoryAxis": {
      "axisColor": "#DADADA",
      "minorGridEnabled": true
    },
    "export": {
      "enabled": true,
      "position": "bottom-right"
    }
  };

  }


  render() {
   

    return (<AmCharts.React style={{width: "100%", height: "500px"}} options={this.data_results_source_line()}/>);
  }
}


export default SwitchStep;
