import React, {Component} from "react";
import {Button, Card, message, Steps,Input,Tag,Tooltip, Icon,Result,Progress,Tabs,Row,Col } from "antd";
import AmCharts from "@amcharts/amcharts3-react";
import "./index.css";

class SwitchStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }





  data_results_source(){

    
    let countofARXIV = 0;
    let countofNTRS = 0;

    let aScoreTotal=0
    let nScoreTotal=0
    let countOfRows = 0;
    let averageScore = []
    
    for(var  i in this.props.data){
      var dataRow = this.props.data[i]
      

      if(dataRow['Datasource']==='NTRS'){
        countOfRows += 1
        countofNTRS += 1
        let nScore = dataRow['Score']

        nScoreTotal = nScoreTotal + nScore

      }else if(dataRow['Datasource']==='ARXIV') {
        countOfRows += 1
        countofARXIV +=1
        let aScore = dataRow['Score']

        aScoreTotal = aScoreTotal + aScore
      }


    }

  return {
    "type": "pie",
    "hideCredits":true,
    "theme": "light",
    "dataProvider": [{
      "Datasource": "NTRS",
      "count": countofNTRS
    }, {
      "Datasource": "ARXIV",
      "count": countofARXIV
    }],
    "valueField": "count",
    "titleField": "Datasource",
    "balloon": {
      "fixedPosition": true
    },
    "export": {
      "enabled": true
    }
  };

  }


  render() {
    return (<AmCharts.React style={{width: "100%", height: "500px"}} options={this.data_results_source()}/>);
  }
}


export default SwitchStep;
