import React from "react"
import {Badge,Progress,Modal,Icon,Statistic,Steps ,Upload ,Avatar,Table, Tag, Space,Card,Skeleton, Form, Input, Button, Checkbox,DatePicker ,Select ,Spin,InputNumber,notification, Row, Col, Divider ,Tabs    } from 'antd';
import Api from '../../Api'
import {Link} from "react-router-dom";
import moment from "moment";

export default class Tablero extends React.Component {

  state = {data:this.props.data,share_total:0}

  componentWillUnmount() {
    Api.RemoveSoket('inciteai_submission/'+this.state.data.id)
  }



  dateformat(date) {
  
  const postDate = moment(new Date(date+'+00:00'));
  const currentDate = moment(new Date());
  const duration = moment.duration(currentDate.diff(postDate));
  const minutes = (duration.asMinutes() | 0);
  const hours = (duration.asHours() | 0);

  switch (true) {
    case minutes === 0:
      return <span>Right now</span>;
    case minutes < 60:
      if(minutes==1){
          return <span>{minutes} minute ago</span>;
      }
      return <span>{minutes} minutes ago</span>;
    case hours < 24:
      if(hours==1){
          return <span>{hours} hour ago</span>;
      }
      return <span>{hours} hours ago</span>;
    default:
      return <span>{postDate.format("DD MMM, YYYY")}</span>
  }
};




  componentDidMount() {
    if(this.state.data.progress!=100){
      Api.Soket('inciteai_submission/'+this.state.data.id,(sd)=>{
        this.setState({data:sd})
      })
    }

    Api.share_list(this.state.data.hash_id).then((data)=>{
        this.setState({share_total:data.data.length})
    })

  }

  
  render() {
    
    return (<Col span={12} sm={12} xs={24}>
      <Card className="gx-card" title={this.state.data.name} extra={<div> 
        
        { this.state.share_total==0 &&
          <Link  to={"/share/"+this.state.data.hash_id}>
            <Button shape="circle" icon="share-alt" />
          </Link>
        }

        { this.state.share_total!=0 &&
        <Badge style={{ backgroundColor: '#0D47A1' }} count={this.state.share_total}>
          <Link  to={"/share/"+this.state.data.hash_id}>
            <Button shape="circle" icon="share-alt" />
          </Link>
        </Badge>
        }
        <Link to={"/submission/details/"+this.state.data.hash_id}> <Button style={{ marginLeft:15 }} type="primary" shape="circle" icon="arrow-right" /> </Link></div>}>
        <Row>
          <Col span={8} xs={10}>
            <Progress type="circle" percent={this.state.data.progress} />
          </Col>
           <Col span={14}>

             <Row style={{marginBottom:15}}>
               <Col span={1}>
                <Icon type="flag" />
               </Col>
               <Col span={20}>
                <b>Status:</b> {this.state.data.state}
               </Col>
             </Row>
             
             <Row style={{marginBottom:15}}>
               <Col span={1}>
                <Icon type="calendar" />
               </Col>
               <Col span={20}>
                <b>Date:</b> {this.dateformat(this.state.data.created_date)}
               </Col>
             </Row>

             <Row>
               <Col span={1}>
                <Icon type="mail" />
               </Col>
               <Col span={20}>
                <b>Send:</b> {this.state.data.email}
               </Col>
             </Row>

          </Col>
        </Row>
      </Card></Col>)
  }




}
