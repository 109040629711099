import React, {Component} from "react";
import AmCharts from "@amcharts/amcharts3-react";
import {Button, Card, message, Steps,Input,Tag,Tooltip, Icon,Result,Progress,Tabs,Row,Col } from "antd";


class Capitals extends Component {
  state={data:[],progress:0}

   async componentDidMount() {
    
    const countOccurrences = years => years.reduce((prev, curr) => (prev[curr] = ++prev[curr] || 1, prev), {});


    let countries = []

    for(var  i in this.props.data){
      var row= this.props.data[i]
      
      if(row['Datasource'] == 'ClinicalTrials.gov' && row['Country'] !='Unknown'){
            // console.log(row['Country']);
            countries.push(row['Country'])

        }
    }

    var countryCountObject = countOccurrences(countries)
    const targetSVG = "m12 0c-4.4183 2.3685e-15 -8 3.5817-8 8 0 1.421 0.3816 2.75 1.0312 3.906 0.1079 0.192 0.221 0.381 0.3438 0.563l6.625 11.531 6.625-11.531c0.102-0.151 0.19-0.311 0.281-0.469l0.063-0.094c0.649-1.156 1.031-2.485 1.031-3.906 0-4.4183-3.582-8-8-8zm0 4c2.209 0 4 1.7909 4 4 0 2.209-1.791 4-4 4-2.2091 0-4-1.791-4-4 0-2.2091 1.7909-4 4-4z";
    

    var res = []
    var s = Object.entries(countryCountObject)
    var count = 0;
    for (const [key, value] of s) {
          count++;
          var data = await fetch('https://nominatim.openstreetmap.org/search?format=json&q='+key).then((response) => response.json())

          let lat = data[0]['lat'];
          let lon = data[0]['lon'];
          this.setState({progress:count/s.length*100})
        res.push({
          //"width": value*2,
          //"height": value*2,
          //"type": "circle",
          //"theme": "none",
          //"value": value,
          "svgPath": targetSVG,
          "title": data[0]['display_name']+" ("+value+")" ,
          "latitude": lat,
          "longitude": lon,
          "label": value
        })

      }

    

    this.setState({data:res})
  }
  render() {


    var config = {
      "type": "map",
      "hideCredits":true,
      "projection": "winkel3",
      "theme": "none",
      "imagesSettings": {
        "rollOverColor": "#089282",
        "rollOverScale": 3,
        "selectedScale": 3,
        "selectedColor": "#089282",
        "color": "#13564e"
      },

      "areasSettings": {
        "unlistedAreasColor": "#15A892",
        "outlineThickness": 0.1
      },

      "dataProvider": {
        "map": "worldLow",
        "images": this.state.data
      },
      "export": {
        "enabled": true
      }
    }
    return (
      <div className="App">
        { this.state.progress!=100 &&
          <div style={{textAlign:'center'}}>
            <h3>Loading Map...</h3>
            <Progress type="circle" percent={Math.round(this.state.progress)} />
          </div>
        }
        { this.state.progress==100 &&
          <AmCharts.React style={{width: "100%", height: "500px"}} options={config}/>
        }
      </div>
    )
  }
}

export default Capitals;
