import React from "react";
import {Route, Switch} from "react-router-dom";

import Account from './account'


const Components = ({match}) => (
  <Switch>
   
    <Route path={`${match.url}/`} component={Account}/>
  </Switch>
);

export default Components;