import React from "react";
import {Route, Switch} from "react-router-dom";
import Api from "../../Api";
import {Table,Spin,Button, Card, message, Modal,Steps,Input,Tag,Tooltip, Icon,Result,Progress,Tabs,Row,Col } from "antd";
import moment from "moment";
import {Link} from "react-router-dom";


export default class Tablero extends React.Component {
  state={title:'',visible:false,email:'',data:[],share:false}

    componentWillUnmount() {
      Api.RemoveSoket('inciteai_submission_share/'+this.state.data.id)
    }


    load(){
      Api.Account().then((acc)=>{
        Api.share_list(this.props.match.params.id).then((data)=>{

          var share = false

          if(acc.data.account_type=='SINGLE'){
                share = true
          }
          if(acc.data.account_type=='ENTERPRISE'){
                share = true
          }

          this.setState({data:data.data,share:share})
        })

      })
    }
    componentDidMount() {
    
      Api.SubmissionGet(this.props.match.params.id).then((data)=>{
        this.setState({title:data.data.name})
      })

      
        
        
        this.load()

    
      
      Api.Soket('inciteai_submission_share/'+this.props.match.params.id,(sd)=>{
          this.load()

      })
   }

 showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {


    Api.share_add(this.state.email,this.props.match.params.id).then(()=>{
      this.load()
    })
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };


   dateformat(date) {
  
  const postDate = moment(new Date(date+'+00:00'));
  const currentDate = moment(new Date());
  const duration = moment.duration(currentDate.diff(postDate));
  const minutes = (duration.asMinutes() | 0);
  const hours = (duration.asHours() | 0);

  switch (true) {
    case minutes === 0:
      return <span>Right now</span>;
    case minutes < 60:
      if(minutes==1){
          return <span>{minutes} minute ago</span>;
      }
      return <span>{minutes} minutes ago</span>;
    case hours < 24:
      if(hours==1){
          return <span>{hours} hour ago</span>;
      }
      return <span>{hours} hours ago</span>;
    default:
      return <span>{postDate.format("DD MMM, YYYY")}</span>
  }
};
  render(){
  


    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Date of creation',
        dataIndex: 'created_date',
        key: 'created_date',
        render: (text, record) => {return this.dateformat(text) },
      },{
        title: 'Last access',
        dataIndex: 'last_access',
        key: 'last_access',
        render: (text, record) => { if(text!=undefined){ return this.dateformat(text) } },
      },
      {
        title: 'Action',
        render: (text, record) => { return (<Button onClick={()=>{ Api.share_remove(record.id).then(()=>{ this.load() }) }} shape="circle" icon="delete" />) },
      }
    ];


     if(this.state.share==false){
      return (<Result
    status="error"
    title="The account type does not support this functionality"
    subTitle="To be able to share projects you must increase your plan"
    extra={[  <Button onClick={()=>{ this.props.history.goBack() }} >Go back</Button>,
      <Link  to={"/pricing"}><Button type="primary">Increase plan</Button></Link>,
    ]}
  >
  </Result>)
    }


   return(<div>
     <div style={{position:'relative'}}>
      <Button onClick={()=>{ this.props.history.goBack() }} style={{float:'left'}} shape="circle" icon="arrow-left" /> <h2 style={{paddingTop:5}}>{this.state.title}</h2>
      <Button onClick={()=>{ this.setState({visible:true}) }} type="primary"  style={{position:'absolute',right:0,top:0}} shape="circle" icon="plus" />
      </div>
    <hr/>
    <Card>
    <Table className="gx-table-responsive" columns={columns} dataSource={this.state.data}/>
    </Card>

    <Modal
          title="Add email"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <p>You will be sharing the information of this project to this email.</p>
          <Input onChange={(a)=>{ this.setState({email:a.target.value}) }} placeholder="Email" />
        </Modal>


   </div>)
  }
}