import React from "react"
import {Progress,Modal,Icon,Statistic,Steps ,Upload ,Avatar,Table, Tag, Space,Card,Skeleton, Form, Input, Button, Checkbox,DatePicker ,Select ,Spin,InputNumber,notification, Row, Col, Divider ,Tabs    } from 'antd';
import Api from '../../Api'


export default class Tablero extends React.Component {

  state = {load:true,name:'',organization:'',modal_visible:false,pass:'',n_pass:''}

  componentWillUnmount() {

  }
  componentDidMount() {
    Api.Account().then((data)=>{
      
      this.setState({data:data.data,load:false,name:data.data.name,organization:data.data.organization,n_pass:''})
    })
  }

    handleCancel = e => {
    this.setState({
          modal_visible: false,
          n_pass:'',
          pass:''
        });
  };
  handleOk = e => {
    
    Api.AccountPassword(this.state.pass,this.state.n_pass).then((data)=>{
      if(data.error!=undefined){
        alert(data.error)
      }else{
        notification["success"]({
        message: 'Updated password...',
        placement:'bottomRight'
        });
        this.setState({
          modal_visible: false,
        });
      }
    })
    
  };
  save(){

    Api.AccountSave(this.state.name,this.state.organization).then(()=>{
        notification["success"]({
        message: 'Updated information...',
        placement:'bottomRight'
        });
    })
    
  }
  render() {
    if(this.state.load){
      return (<div style={{textAlign:'center'}}><Spin  style={{marginTop:150}} size="large" /></div>)
    }
    
    return (<div>
      <Card className="gx-card">
        <h2>Account information </h2>
        <hr/>
        <div style={{ marginTop:50 }}>
          <Row style={{marginBottom:30}}>
            <Col style={{textAlign:'right'}} span={4}>
                  <b>Name:</b>
                 </Col>
                 <Col span={18}>
                  <Input style={{width:250}} type="text" value={this.state.name} onChange={(e)=>{ this.setState({name:e.target.value}) }} />
                 </Col>
          </Row>
          <Row style={{marginBottom:30}}>
            <Col style={{textAlign:'right'}} span={4}>
                  <b>Organization:</b>
                 </Col>
                 <Col span={18}>
                  <Input style={{width:250}} type="text" value={this.state.organization} onChange={(e)=>{ this.setState({organization:e.target.value}) }} />
                 </Col>
          </Row>
          <Row style={{marginBottom:30}}>
            <Col style={{textAlign:'right'}} span={4}>
                  <b>Email:</b>
                 </Col>
                 <Col span={18}>
                 {this.state.data.email}
                 </Col>
          </Row>
          <hr/>
          <Row style={{marginBottom:30}}>
            <Col style={{textAlign:'right'}} span={4}>
                 </Col>
                 <Col span={18}>
                  <Button onClick={()=>{ this.save()}} type="primary">Save</Button> 
                  <Button onClick={()=>{ this.setState({modal_visible: true}); }} type="link">Change Password</Button>
                 </Col>
          </Row>
          
        </div>
      </Card>


       <Modal
          title="Change password"
          visible={this.state.modal_visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
        <div style={{margin:20}}>
        <Row >
            <Col span={24}>
                  <b>Password:</b>
            </Col>
          </Row>
        <Row >
            <Col span={24}>
              <Input type="password" value={this.state.pass} onChange={(e)=>{ this.setState({pass:e.target.value}) }} />
            </Col>
          </Row>


          <Row style={{marginTop:20}}>
            <Col span={24}>
                  <b>New Password:</b>
            </Col>
          </Row>
        <Row >
            <Col span={24}>
              <Input type="password" value={this.state.n_pass} onChange={(e)=>{ this.setState({n_pass:e.target.value}) }} />
            </Col>
          </Row>



          </div>
        </Modal>


    </div>)
                
  }




}
