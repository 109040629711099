import React from "react";
import {Route, Switch} from "react-router-dom";

import List from "./list";

export default class Tablero extends React.Component {

    componentDidMount() {
    

    
   }


  render(){
  


   return(<div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${this.props.match.url}/:id`} component={List}/>
    </Switch>
  </div>)
  }
}