import React from "react";
import {Route, Switch} from "react-router-dom";

import List from "./list";
import Details from './details'
import New from './new'
import Api from "../../Api";

export default class Tablero extends React.Component {

    componentDidMount() {
    

    
   }


  render(){
  


   return(<div className="gx-main-content-wrapper">
    <Switch>
        <Route path={`${this.props.match.url}/new`} component={New}/>
      <Route path={`${this.props.match.url}/details/:id`} component={Details}/>
      <Route path={`${this.props.match.url}/`} component={List}/>
    </Switch>
  </div>)
  }
}