import React from "react"
import {Progress,Modal,Icon,Statistic,Steps ,Upload ,Avatar,Table, Tag, Space,Card,Skeleton, Form, Input, Button, Checkbox,DatePicker ,Select ,Spin,InputNumber,notification, Row, Col, Divider ,Tabs    } from 'antd';
import Api from '../../Api'
import CardProject from './CardProject'

export default class Tablero extends React.Component {

  state = {load:true}

  componentWillUnmount() {

  }
  componentDidMount() {
    Api.SubmissionList().then((data)=>{
      this.setState({data:data.data,load:false})
    })
  }

  items(){
    var res = []
    for(var i in this.state.data){
      var item = this.state.data[i]
      res.push(<CardProject data={item} />)
    }
    return res
  }
  render() {
    if(this.state.load){
      return (<div style={{textAlign:'center'}}><Spin  style={{marginTop:150}} size="large" /></div>)
    }
    
    return (<div>
      <div style={{textAlign:'center'}}>
      <Button type="primary" onClick={()=>{ this.props.history.push('/submission/new') }} >New project</Button>
      </div>
    <Row>{this.items()}
                
                
              </Row></div>)
  }




}
