import React from "react";
import {Button} from "antd";

import IntlMessages from "util/IntlMessages";

const
  PriceItem = ({styleName, headerStyle, itemStyle, footerStyle,payment}) => {
    return (
      <div className={`${styleName}`}>
        <div className={`${headerStyle}`}>
          <h2 className="gx-price"><i className="icon icon-halfstar"/>$49.00</h2>
          <p className="gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0">ENTERPRISE ACCOUNT</p>
          <p>Per month, billed annually</p>
        </div>

        <div className={`${itemStyle}`}>
          <ul className="gx-package-items">
            <li>
              <i className="icon icon-check"/>
              <span>All benefits of the Single Account</span>
            </li>
            <li>
              <i className="icon icon-check"/>
              <span>Unlimited searches</span>
            </li>
            <li>
              <i className="icon icon-check"/>
              <span>Up to 3 users per account</span>
            </li>
     
          </ul>

          <div className="gx-package-footer">
            <Button  onClick={payment}  type="primary" className={`${footerStyle}`}><IntlMessages
              id="pricingTable.buyNow"/></Button>
          </div>
        </div>
      </div>
    )
  };

export default PriceItem;

