import React, {Component} from "react";
import {Button, Card, message, Steps,Input,Tag,Tooltip, Icon,Result,Progress,Tabs,Row,Col } from "antd";
import AmCharts from "@amcharts/amcharts3-react";
import "./index.css";

class SwitchStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
     };
  }




  data_patent_documents_results(){

    let scoresList = [];
    let titleList = [];
    let usptoList=[];

    for(var  i in this.props.data){
      var element= this.props.data[i]
      let score = element['Score']
        // let title = element['Title'].slice(0, 30)
        let title = element['Title'].replace(/(\S+\s*){1,11}/g, "$&\n")
        let abstract = element['Text'].replace(/(\S+\s*){1,11}/g, "$&\n")
        let authors = element['Author']
        if(element['Datasource']==='USPTO'){
            let usptoRow=element['Datasource']
            usptoList.push(usptoRow)
            scoresList.push({score:score.toFixed(4),title:title, abstract:abstract})
            

        }
      
    }



    


  return {
    "type": "serial",
    "theme": "light",
    "hideCredits":true,
    "listeners": [{
      "event": "clickGraphItem",
      "method": (a)=>{ this.props.showModal(a.item.dataContext.title,a.item.dataContext.abstract);console.log(a) } 
    },{
    "event": "drawn",
    "method": (e) =>{
      e.chart.zoomToIndexes(0,10)
    }
  }],
    "dataProvider": scoresList,
    "valueAxes": [{
      "gridColor": "#FFFFFF",
      "gridAlpha": 0.2,
      "dashLength": 0
    }],
    "gridAboveGraphs": true,
    "startDuration": 1,
    "chartScrollbar": {
      "graph": "g1",
      "scrollbarHeight": 40,
      "backgroundAlpha": 0,
      "selectedBackgroundAlpha": 0.1,
      "selectedBackgroundColor": "#888888",
      "graphFillAlpha": 0,
      "graphLineAlpha": 0.5,
      "selectedGraphFillAlpha": 0,
      "selectedGraphLineAlpha": 1,
      "autoGridCount": true,
      "color": "#AAAAAA"
    },
    "graphs": [{
      "balloonText": "[[category]]: <b>[[title]]</b>",
      "fillAlphas": 0.8,
      "lineAlpha": 0.2,
      "type": "column",
      "valueField": "score"
    }],
    "chartCursor": {
      "categoryBalloonEnabled": false,
      "cursorAlpha": 0,
      "zoomable": false
    },
    "categoryField": "score",
    "categoryAxis": {
      "gridPosition": "start",
      "gridAlpha": 0,
      "tickPosition": "start",
      "tickLength": 20
    },
    "export": {
      "enabled": true
    }

  };

  }


  render() {
    
    return (<AmCharts.React style={{width: "100%", height: "500px"}} options={this.data_patent_documents_results()}/>);
  }
}


export default SwitchStep;
