import React from "react";
import {Route, Switch} from "react-router-dom";

import Submission from "./submission/index";
import Account from "./account/index";
import Share from "./share/index";
import Api from "../Api";
import Pricing from './pricing/index'

export default class Tablero extends React.Component {

    componentDidMount() {
    Api.SaveUserGeo().then((data)=>{
      
    })
   }


  render(){
  


   return(<div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${this.props.match.url}submission`} component={Submission}/>
      <Route path={`${this.props.match.url}account`} component={Account}/>
      <Route path={`${this.props.match.url}share`} component={Share}/>
      <Route path={`${this.props.match.url}pricing`} component={Pricing}/>
    </Switch>
  </div>)
  }
}
