import React, {Component} from "react";
import {Form, Select,Spin,Button, Card, message, Modal,Steps,Input,Tag,Tooltip, Icon,Result,Progress,Tabs,Row,Col } from "antd";
import Api from '../../Api'


const { Option } = Select;


class SwitchStep extends Component {
  state={load:false,ex:false}

  componentWillUnmount() {
  }
  componentDidMount() {
    Api.recover_password_get(this.props.match.params.id).then((data)=>{
      if(data.error!=undefined){
        this.setState({ex:true})
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      if(values.password!=values.password_2){
        alert('The password does not match')
      }else{

          Api.recover_password_update(values.password,this.props.match.params.id).then((data)=>{
            if(data.error!=undefined){
              alert(data.error)
            }else{
              localStorage.setItem('user_id', data.token);
              window.location.href = '/'
            }
          })
              
      }

      

      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  handleSelectChange = value => {
    console.log(value);
    this.props.form.setFieldsValue({
      note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
    });
  };
  
  render() {

    if(this.state.ex){
      return ( <Result
        status="error"
        title="Failed"
        subTitle="The request time has expired or has already been used."
        extra={[<Button type="primary" key="console">Go Home</Button>]} />)
    }

    if(this.state.load){
      return (<div style={{textAlign:'center'}}><Spin  style={{marginTop:150}} size="large" /></div>)
    }

    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{margin: 'auto',width:'50%'}}>
        <div style={{marginTop:20}}>
          <Card  className="gx-card" title="New Password">



             <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit}>
        <Form.Item label="New password!">
          {getFieldDecorator('password', {
            rules: [{ required: true, message: '' }],
          })(<Input type="password" />)}
        </Form.Item>
        <Form.Item label="Repeat password">
          {getFieldDecorator('password_2', {
            rules: [{ required: true, message: '' }],
          })(<Input type="password" />)}
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>



          </Card>
        </div>
      </div>
    );
  }
}


export default Form.create({ name: 'coordinated' })(SwitchStep);