import React from "react";
import {Card, Divider, Icon, Table,Button,Input,Pagination} from "antd";
import Highlighter from 'react-highlight-words';
import { CSVLink } from 'react-csv';






export default class Tablero extends React.Component {

  state = {export:false,current:1, pageSize:10}

   handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  load_page(){
    return this.props.data.slice(this.state.pageSize*(this.state.current-1), this.state.pageSize*this.state.current);
  }

  render() {
  


   var getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });



        const columns = [
  {
    title: 'Score',
    dataIndex: 'Score',
    key: 'Score',
    ...getColumnSearchProps('Score'),

  },
  {
    title: 'Title',
    dataIndex: 'Title',
    key: 'Title',
    ...getColumnSearchProps('Title'),
  },
  {
    title: 'Categories',
    dataIndex: 'Categories',
    key: 'Categories',
    ...getColumnSearchProps('Categories'),
  }
];
    
    return (<div>
         <Button
                variant="contained"
                type="primary"
                className='export-btn'
                text="Export Data"
            ><CSVLink
                    data={this.props.data}
                    filename={'data.csv'}
                    style={{ "textDecoration": "none", "color": "#ffffff" }}
                >
                {'Export Data'}
                </CSVLink>

                </Button>
       
        <Table className="gx-table-responsive" pagination={false} columns={columns} dataSource={this.load_page()}/>

         <Pagination showSizeChanger
      onShowSizeChange={(current,pageSize)=>{  this.setState({current:current, pageSize:pageSize}); }}
      onChange={(e)=>{ this.setState({current:e});}}
      defaultCurrent={1}
      pageSizeOptions={[10,25,50,100]}
      total={this.props.data.length}/>

      </div>)
  }




}


