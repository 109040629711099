import React, {Component} from "react";
import {Button, Card, message, Steps,Input,Tag,Tooltip, Icon,Result,Progress,Tabs,Row,Col } from "antd";
import AmCharts from "@amcharts/amcharts3-react";
import "./index.css";

class SwitchStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  data_results_source_line(){

    let years = []
    const countOccurrences = years => years.reduce((prev, curr) => (prev[curr] = ++prev[curr] || 1, prev), {});
    
    for(var  i in this.props.data){
        var dataRow = this.props.data[i]
        if(dataRow['Datasource']==='ClinicalTrials.gov'){
            let year = dataRow['Date'].split(', ')[1];
            
            years.push(year);

        }
        
    }



    var usptoYearCount = countOccurrences(years)
    var chartData = []
    for (const [key, value] of Object.entries(usptoYearCount)) {
        chartData.push({year:key,value:value})
    }


  return {
    "type": "serial",
    "hideCredits":true,
    "theme": "light",
    "legend": {
      "useGraphSettings": true
    },
    "dataProvider": chartData,
    "synchronizeGrid": true,
    "valueAxes": [{
      "id": "v1",
      "axisColor": "#FF6600",
      "axisThickness": 2,
      "axisAlpha": 1,
      "position": "left"
    }],
    "graphs": [{
      "valueAxis": "v1",
      "lineColor": "#FF6600",
      "bullet": "round",
      "bulletBorderThickness": 1,
      "hideBulletsCount": 30,
      "title": "#ARXIV by Year",
      "valueField": "value",
      "fillAlphas": 0
    }],
    "chartScrollbar": {},
    "chartCursor": {
      "cursorPosition": "mouse"
    },
    "categoryField": "year",
    "categoryAxis": {
      "axisColor": "#DADADA",
      "minorGridEnabled": true
    },
    "export": {
      "enabled": true,
      "position": "bottom-right"
    }
  };

  }


  render() {
   

    return (<AmCharts.React style={{width: "100%", height: "500px"}} options={this.data_results_source_line()}/>);
  }
}


export default SwitchStep;
