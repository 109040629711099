import React, {Component} from "react";
import {Button, Card, message, Steps,Input,Tag,Tooltip, Icon,Result,Progress,Tabs,Row,Col } from "antd";
import AmCharts from "@amcharts/amcharts3-react";
import "./index.css";

const { TabPane } = Tabs;
const Step = Steps.Step;
const {TextArea} = Input;

class SwitchStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  data_researcher(){

    const countOccurrences = years => years.reduce((prev, curr) => (prev[curr] = ++prev[curr] || 1, prev), {});


    var res = []

    let years = []
    let categoryList=[];
    let scoresList = [];
    let randomColors=[];
    let titleList = [];
    let pdlList = [];
    let countries = [];


    for(var  i in this.props.data){
      var  dataRow = this.props.data[i]
      if(dataRow['Datasource']==='PDL'){
            let pdlRow = dataRow['Datasource']
            let country = dataRow['Country']
            let year = dataRow['Date'].substr(0,4);

            let category = dataRow['Categories'];
            let scores = dataRow['Score']
            let title = dataRow['Organization'].replace(/(\S+\s*){1,11}/g, "$&\n")
            let link = dataRow['Url'];
            let abstract = dataRow['Text'].replace(/(\S+\s*){1,11}/g, "$&\n")
            titleList.push('Title: ' + title + '  ----  '+'Description: ' + abstract + '  ----  '+ 'Link: ' + link);
            pdlList.push(pdlRow)
            scoresList.push(scores)
            if(year != ''){
                years.push(year);
            }

            categoryList.push(category)
            if(country !=''){

                countries.push(country);
            }

        }


      
    }

    var pdlYearCount = countOccurrences(years)


    let labels = []
    let values = []
    for (const [key, value] of Object.entries(pdlYearCount)) {
        
        res.push({
        "year": key,
        "count": value
      })

    }
  return {
    "hideCredits":true,
    "type": "serial",
    "theme": "light",
    "dataProvider": res,
    "startDuration": 1.5,
    "zoomControl": {
    "zoomControlEnabled": true
    },
    "valueAxes": [{
      "gridColor": "#FFFFFF",
      "gridAlpha": 0,
      "position": "bottom"
    }],
    "gridAboveGraphs": true,
    "chartScrollbar": {
      "graph": "g1",
      "offset": 15,
      "scrollbarHeight": 40,
      "backgroundAlpha": 0,
      "selectedBackgroundAlpha": 0.1,
      "selectedBackgroundColor": "#888888",
      "graphFillAlpha": 0,
      "graphLineAlpha": 0.5,
      "selectedGraphFillAlpha": 0,
      "selectedGraphLineAlpha": 1,
      "autoGridCount": true,
      "color": "#AAAAAA"
    },
    "graphs": [{
      "balloonText": "[[category]]: <b>[[value]]</b>",
      "fillAlphas": 0.8,
      "lineAlpha": 0.2,
      "type": "column",
      "valueField": "count"
    }],
    "chartCursor": {
      "categoryBalloonEnabled": false,
      "cursorAlpha": 0,
      "zoomable": true,
      "zoomOutButtonAlpha":10,
    },
    "categoryField": "year",
    "categoryAxis": {
      "gridPosition": "start",
      "gridAlpha": 0,
      "tickPosition": "start",
      "tickLength": 20
    },
    "export": {
      "enabled": true
    }

  };
  }
  



  render() {
  

    return (<AmCharts.React style={{width: "100%", height: "500px"}} options={this.data_researcher()}/>);
  }
}


export default SwitchStep;
