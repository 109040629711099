import React from "react";
import {Button} from "antd";

import IntlMessages from "util/IntlMessages";

const
  PriceItem = ({styleName, headerStyle, itemStyle, footerStyle,payment}) => {
    return (
      <div className={`${styleName}`}>

        <div className={`${headerStyle}`}>
          <h2 className="gx-price"><i className="icon icon-halfstar"/>$29.00</h2>
          <p className="gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0">SINGLE ACCOUNT
          </p>
          <p>Per month, billed annually</p>
        </div>

        <div className={`${itemStyle}`}>
          <ul className="gx-package-items">
            <li>
              <i className="icon icon-check"/>
              <span>10 searches per month</span>
            </li>
            <li>
              <i className="icon icon-check"/>
              <span>Access to all graphs and results</span>
            </li>
            <li>
              <i className="icon icon-check"/>
              <span>Share your results online</span>
            </li>
          </ul>

          <div className="gx-package-footer">
            <Button type="primary" onClick={payment} className={`${footerStyle}`}><IntlMessages
              id="pricingTable.buyNow"/></Button>
          </div>
        </div>
      </div>
    )
  };

export default PriceItem;

